import { useEffect } from 'react'
import { dispatchedActions } from '@dis/redux'
import { errorCodeModal, ErrorCodeModalProps } from '@dis/modals/src/errorCodeModal'

export type UseErrorCodeModalProps = ErrorCodeModalProps & {
  disabled?: boolean
}

export const useErrorCodeModal = ({
  disabled = false,
  errorCode,
  ...restProps
}: UseErrorCodeModalProps) => {
  useEffect(() => {
    if (!disabled && errorCode) {
      const modalId = dispatchedActions.centralModalDialog.showModalDialog(
        errorCodeModal({
          ...restProps,
          errorCode,
        }),
      )

      return () => {
        dispatchedActions.centralModalDialog.hideModalDialog(modalId)
      }
    }
  }, [disabled, errorCode, restProps])

  return undefined
}
