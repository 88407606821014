import { useCallback, useRef, useState } from 'react'
import {
  WsEnhancedErrorMessage,
  WsEnhancedResponseMessage,
  WsMessageModel,
  WsOperationType,
} from '@dis/types/src/wsModels'
import { dispatchedActions, useAppSelector } from '@dis/redux'
import { selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'
import {
  WsChangeAtlasDetail,
  WsChangeAtlasFolderJourney,
  WsChangeCapability,
  WsChangeChannelVisibility,
  WsChangeFolderHeader,
  WsChangeGroupsMGMTatlas,
  WsChangeGroupsMGMTname,
  WsChangeHandlerForWYSIWYG,
  WsChangeJourneyGridProvider,
  WsChangeKpmgTableOfUsers,
  WsChangeMessageModel,
  WsChangeOperation,
  WsChangePersonaColumnValueMessageModel,
  WsChangePersonas,
  WsChangePublicationStatus,
  WsChangeTableOfBacklog,
  WsChangeTableOfUsersManagement,
  WsChangeTemplateDocument,
  WsChangeTenantLimits,
  WsChangeTenantMGMT,
  WsChangeUserPreferencesMessageModel,
  WsChangeChannel,
  WsChangeTenant,
  WsPublishSubcapability,
  WsChangeCategory,
} from '@dis/types/src/wsChangeModels'
import { Language } from '@dis/types/src/GeneralTypes'
import { i18next } from '@dis/languages'
import { LANGUAGES, LanguagesUnion } from '@dis/constants'
import { selectSelectedPersonaId } from '@dis/redux/src/personas/personasSelectors'
import {
  AtlasFolderInlineChangeForm,
  BacklogFormField,
  CreateOrEditCategoriesForm,
  CreateOrEditTenantForm,
  SaveOrEditTemplateForm,
  TenantLimitsForm,
} from '@dis/types/src/forms'
import { Folder } from '@dis/types/src/FolderTypes'
import { selectSelectedJourneyId } from '@dis/redux/src/journeys/journeysSelectors'
import { WsChangeDataModel } from '@dis/types/src/wsChangeDataModels'
import { PersonaAction } from '@dis/modules/src/journey/header/Personas'
import { Api } from '@dis/api'
import { AdministrationTenant } from '@dis/types/src/TenantTypes'
import { CapabilityText } from '@dis/types/src/CapabilityTypes'
import { SectorUnion } from '@dis/modules/src/ConnectedCapabilities/constants'
import { JourneySubscribe } from '@dis/modules/src/journey/grid/types'
import { useTypedParams } from './useTypedParams'

type Props = {
  onData?: (data: WsEnhancedResponseMessage) => void
  useInternalErrorHandling?: boolean
}

export const useChange = (props?: Props) => {
  const [data, setData] = useState<WsEnhancedResponseMessage>()
  const [error, setError] = useState<WsEnhancedErrorMessage['error']>()
  const [loading, setLoading] = useState(false)

  const onData = useRef(props?.onData)
  onData.current = props?.onData

  const useInternalErrorHandling = useRef(props?.useInternalErrorHandling)
  useInternalErrorHandling.current = props?.useInternalErrorHandling

  const send = useCallback((message: WsChangeMessageModel) => {
    setLoading(true)

    Api.sendChangeMessage(
      {
        ...message,
        useInternalErrorHandling: useInternalErrorHandling.current,
      },
      (newData, error) => {
        setError(error)
        setData(newData)
        setLoading(false)

        onData.current?.(newData)
      },
    )
  }, [])

  return {
    data,
    error,
    loading,
    send,
  }
}

export const useChangeLanguage = () => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const { send: sendWrapper, loading, data: changeData } = useChange()

  const send = useCallback(
    (locale: Language) => {
      if (locale) {
        // Tenant user has to send valid tenantId, KPMG user can send undefined
        const message: WsChangeUserPreferencesMessageModel = {
          change: {
            data: {
              locale,
            },
            model: WsMessageModel.UserPreference,
          },
          tenantId: tenantId || 0,
        }

        sendWrapper(message)

        // TODO: Setting lang props in the redux, i18next and HTML is made here and then a duplicity code in a saga.

        dispatchedActions.user.setUserLanguage(locale)

        i18next.changeLanguage(locale)

        document.documentElement.setAttribute('lang', locale)

        if (locale === LANGUAGES.ar) {
          document.documentElement.setAttribute('dir', 'rtl')
        } else {
          document.documentElement.setAttribute('dir', '')
        }
      }
    },
    [tenantId, sendWrapper],
  )

  return {
    changeData,
    loading,
    send,
  }
}

export const useChangeAtlasFolderJourney = (props?: Props) => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const { send: sendWrapper, loading, data: changeData } = useChange(props)

  const send = useCallback(
    ({ id, name, model }: AtlasFolderInlineChangeForm) => {
      if (id && tenantId) {
        const message: WsChangeAtlasFolderJourney = {
          change: {
            data: { documents: [], folders: [], id: id.toString(), name },
            id: id.toString(),
            model,
          },
          tenantId,
        }
        sendWrapper(message)
      }
    },
    [tenantId, sendWrapper],
  )

  return {
    changeData,
    loading,
    send,
  }
}

export const useChangeTableOfBacklog = (props?: Props) => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const { send: sendWrapper, loading, data: changeData } = useChange(props)

  const send = useCallback(
    (data: BacklogFormField) => {
      if (data && tenantId) {
        const message: WsChangeTableOfBacklog = {
          change: {
            data,
            id: data.id?.toString(),
            model: WsMessageModel.UserStory,
          },
          tenantId,
        }
        sendWrapper(message)
      }
    },
    [tenantId, sendWrapper],
  )

  return {
    changeData,
    loading,
    send,
  }
}

export const useChangeChannel = (props?: Props) => {
  const tenantId = useAppSelector(selectSelectedTenantId)

  const { send: sendWrapper, loading, data: changeData } = useChange(props)

  const send = useCallback(
    (data: WsChangeChannel[WsOperationType.Change]['data']) => {
      if (tenantId) {
        const message: WsChangeChannel = {
          change: {
            data,
            model: WsMessageModel.Channel,
          },
          tenantId,
        }

        sendWrapper(message)
      }
    },
    [tenantId, sendWrapper],
  )

  return {
    changeData,
    loading,
    send,
  }
}

export const useChangeCapability = (props?: Props) => {
  const { send: sendWrapper, loading, data: changeData } = useChange(props)

  const send = useCallback(
    ({
      model,
      data,
      language,
      id,
      sector,
    }: {
      data: Required<CapabilityText>
      id: number // Capability or subcapability ID
      language: LanguagesUnion
      model: WsMessageModel.Capability | WsMessageModel.SubCapability
      sector: SectorUnion
    }) => {
      const message: WsChangeCapability = {
        change: {
          data: {
            desc: data.desc,
            lang: language,
            name: data.name,
            sectorCode: sector,
          },
          id: id.toString(),
          model,
        },
        tenantId: 0,
      }
      sendWrapper(message)
    },
    [sendWrapper],
  )

  return {
    changeData,
    loading,
    send,
  }
}

export const usePublishSubcapability = (props?: Props) => {
  const { send: sendWrapper, loading, data: changeData } = useChange(props)

  const send = useCallback(
    (subcapabilityId: number) => {
      const message: WsPublishSubcapability = {
        change: {
          id: subcapabilityId.toString(),
          model: WsMessageModel.SubCapabilityPublic,
        },
        tenantId: 0,
      }
      sendWrapper(message)
    },
    [sendWrapper],
  )

  return {
    changeData,
    loading,
    send,
  }
}

export const useChangeFolderHeader = (props?: Props) => {
  const { send: sendWrapper, loading, data: changeData } = useChange(props)
  const tenantId = useAppSelector(selectSelectedTenantId)

  const send = useCallback(
    (data: Folder) => {
      if (data && tenantId) {
        const message: WsChangeFolderHeader = {
          change: {
            data: {
              atlasid: data?.atlasid?.toString(),
              documents: data.documents,
              id: data.id?.toString(),
              name: data.name,
            },
            id: data.id?.toString(),
            model: WsMessageModel.Folder,
          },
          tenantId,
        }
        sendWrapper(message)
      }
    },
    [tenantId, sendWrapper],
  )

  return {
    changeData,
    loading,
    send,
  }
}

export const useChangeKpmgTableOfUsers = (props?: Props) => {
  const { send: sendWrapper, loading, data: changeData } = useChange(props)

  const send = useCallback(
    ({ tenants, role, id }: WsChangeKpmgTableOfUsers['change']['data']) => {
      if (id) {
        const message: WsChangeKpmgTableOfUsers = {
          change: {
            data: {
              id,
              role,
              tenants,
            },
            id: id.toString(),
            model: WsMessageModel.KpmgUser,
          },
          tenantId: 0,
        }
        sendWrapper(message)
      }
    },
    [sendWrapper],
  )

  return {
    changeData,
    loading,
    send,
  }
}

export const useChangePublicationStatus = (props?: Props) => {
  const { send: sendWrapper, loading, data: changeData } = useChange(props)

  const tenantId = useAppSelector(selectSelectedTenantId)
  const selectedJourneyId = useAppSelector(selectSelectedJourneyId)

  const send = useCallback(
    (journeyInfo: NonNullable<JourneySubscribe['info']>) => {
      if (selectedJourneyId && tenantId) {
        const message: WsChangePublicationStatus = {
          change: {
            data: [
              {
                operation: WsChangeOperation.Update,
                path: 'info',
                value: journeyInfo,
              },
            ],
            id: selectedJourneyId?.toString(),
            model: WsMessageModel.Document,
          },
          tenantId,
        }
        sendWrapper(message)
      }
    },
    [selectedJourneyId, tenantId, sendWrapper],
  )

  return {
    changeData,
    loading,
    send,
  }
}

export const useChangeTableOfUsersManagement = (props?: Props) => {
  const { send: sendWrapper, loading, data: changeData } = useChange(props)

  /**
   * groups, role: if undefined, then BE doesn't change this prop in DB
   * id: user ID
   */
  const send = useCallback(
    ({
      groups,
      role,
      id,
      tenantId,
    }: WsChangeTableOfUsersManagement['change']['data'] & { tenantId?: number }) => {
      if (tenantId) {
        const message: WsChangeTableOfUsersManagement = {
          change: {
            data: {
              groups,
              id,
              role,
            },
            id: id.toString(),
            model: WsMessageModel.User,
          },
          tenantId,
        }
        sendWrapper(message)
      }
    },
    [sendWrapper],
  )

  return {
    changeData,
    loading,
    send,
  }
}

export const useChangeJourneyGridProvider = (props?: Props) => {
  const onData = useRef(props?.onData)
  onData.current = props?.onData

  const {
    send: sendWrapper,
    loading,
    data: changeData,
    error,
  } = useChange({
    onData: props?.onData,
  })

  const tenantId = useAppSelector(selectSelectedTenantId)
  const selectedJourneyId = useAppSelector(selectSelectedJourneyId)

  // TODO: change function argument to data: WsChangeDataModel
  const send = useCallback(
    ({ data }: { data?: WsChangeDataModel }) => {
      if (data && tenantId && selectedJourneyId) {
        const message: WsChangeJourneyGridProvider = {
          change: {
            data,
            id: selectedJourneyId?.toString(),
            model: WsMessageModel.Document,
          },
          tenantId,
        }
        sendWrapper(message)
      }
    },
    [selectedJourneyId, tenantId, sendWrapper],
  )

  return {
    changeData,
    error,
    loading,
    send,
  }
}

export const useChangePersonas = (props?: Props) => {
  const { send: sendWrapper, loading, data: changeData } = useChange(props)

  const tenantId = useAppSelector(selectSelectedTenantId)
  const selectedJourneyId = useAppSelector(selectSelectedJourneyId)

  const send = useCallback(
    ({
      data,
    }: {
      data?: {
        operation?: PersonaAction
        path?: string
        value?: { desc: string; id: string; image: string; model: string; name: string }
      }[]
    }) => {
      if (tenantId) {
        const message: WsChangePersonas = {
          change: { data, id: selectedJourneyId?.toString(), model: WsMessageModel.Document },
          tenantId,
        }
        sendWrapper(message)
      }
    },
    [selectedJourneyId, tenantId, sendWrapper],
  )

  return {
    changeData,
    loading,
    send,
  }
}

export const useChangePersona = (props?: {
  onData: (data?: WsEnhancedResponseMessage) => void
}) => {
  const tenantId = useAppSelector(selectSelectedTenantId)
  const { personaId } = useTypedParams()

  const onData = props?.onData

  const { send: sendWrapper, loading, error, data: changeData } = useChange({ onData })

  const changePersona = useCallback(
    (data: WsChangePersonaColumnValueMessageModel['change']['data']) => {
      if (data && personaId && tenantId) {
        const message: WsChangePersonaColumnValueMessageModel = {
          change: {
            data,
            id: personaId.toString(),
            model: WsMessageModel.Persona,
          },
          tenantId,
        }

        if (message) sendWrapper(message)
      }
    },
    [personaId, tenantId, sendWrapper],
  )

  return {
    changeData,
    changePersona,
    error,
    loading,
  }
}

export const useChangeHandlerForWYSIWYG = (props?: Props) => {
  const { send: sendWrapper, loading, data: changeData } = useChange(props)

  const tenantId = useAppSelector(selectSelectedTenantId)
  const selectedPersonaId = useAppSelector(selectSelectedPersonaId)

  const send = useCallback(
    ({
      value,
      path,
    }: {
      path?: string
      value: {
        desc?: string
        guid?: string
        image?: string
        model?: string
        position?: number
        title?: string
        value?: string
      }
    }) => {
      if (tenantId) {
        const message: WsChangeHandlerForWYSIWYG = {
          change: {
            data: [
              {
                operation: WsChangeOperation.Update,
                path: path ?? '',
                value: value,
              },
            ],
            id: selectedPersonaId?.toString(),
            model: WsMessageModel.Persona,
          },
          tenantId,
        }

        sendWrapper(message)
      }
    },
    [selectedPersonaId, tenantId, sendWrapper],
  )

  return {
    changeData,
    loading,
    send,
  }
}

export const useChangeAtlasDetail = (props?: Props) => {
  const { send: sendWrapper, loading, data: changeData } = useChange(props)

  const tenantId = useAppSelector(selectSelectedTenantId)

  const send = useCallback(
    ({ data }: { data: { id?: number; name?: string } }) => {
      if (tenantId) {
        const message: WsChangeAtlasDetail = {
          change: {
            data: { documents: [], folders: [], id: data.id?.toString(), name: data.name },
            id: data.id?.toString(),
            model: WsMessageModel.Atlas,
          },
          tenantId,
        }

        sendWrapper(message)
      }
    },
    [tenantId, sendWrapper],
  )

  return {
    changeData,
    loading,
    send,
  }
}

export const useChangeTenantMGMT = (props?: Props) => {
  const { send: sendWrapper, loading, data: changeData } = useChange(props)

  const tenantId = useAppSelector(selectSelectedTenantId)

  const send = useCallback(
    ({ data }: { data: AdministrationTenant }) => {
      if (tenantId) {
        const message: WsChangeTenantMGMT = {
          change: {
            data: {
              ...data,
              atlases: [], // not necessary to send atlases so send just empty array
            },
            id: tenantId?.toString(),
            model: WsMessageModel.Tenant,
          },
          tenantId,
        }
        sendWrapper(message)
      }
    },
    [tenantId, sendWrapper],
  )

  return {
    changeData,
    loading,
    send,
  }
}

export const useChangeGroupsMGMTname = (props?: Props) => {
  const { send: sendWrapper, loading, data: changeData, error } = useChange(props)

  const send = useCallback(
    ({ groupId, name, tenantId }: { groupId: number; name: string; tenantId?: number }) => {
      if (tenantId) {
        const message: WsChangeGroupsMGMTname = {
          change: {
            data: {
              groupId,
              name,
            },
            id: groupId.toString(),
            model: WsMessageModel.Group,
          },
          tenantId,
        }

        sendWrapper(message)
      }
    },
    [sendWrapper],
  )

  return {
    changeData,
    error,
    loading,
    send,
  }
}

export const useChangeGroupsMGMTatlas = (props?: Props) => {
  const { send: sendWrapper, loading, data: changeData, error } = useChange(props)

  const send = useCallback(
    ({
      groupId,
      items,
      tenantId,
    }: {
      groupId: number
      items: WsChangeGroupsMGMTatlas['change']['data']['items']
      tenantId: number
    }) => {
      const message: WsChangeGroupsMGMTatlas = {
        change: {
          data: {
            groupId,
            // Convert empty string to undefined due to BE
            items: items.map((item) => ({ ...item, accessLevel: item.accessLevel || undefined })),
          },
          id: tenantId?.toString() || '0',
          model: WsMessageModel.AtlasGroup,
        },
        tenantId: tenantId || 0,
      }
      sendWrapper(message)
    },
    [sendWrapper],
  )

  return {
    changeData,
    error,
    loading,
    send,
  }
}

export const useChangeTenantLimits = (props?: Props) => {
  const { send: sendWrapper, loading, data: changeData } = useChange(props)

  const tenantId = useAppSelector(selectSelectedTenantId)

  const send = useCallback(
    ({ data }: { data: TenantLimitsForm }) => {
      if (tenantId) {
        const message: WsChangeTenantLimits = {
          change: {
            data: data,
            id: tenantId?.toString(),
            model: WsMessageModel.Tenant,
          },
          tenantId,
        }
        sendWrapper(message)
      }
    },
    [tenantId, sendWrapper],
  )

  return {
    changeData,
    loading,
    send,
  }
}

export const useChangeTemplate = (props?: Props) => {
  const { send: sendWrapper, loading, error, data: changeData } = useChange(props)

  const tenantId = useAppSelector(selectSelectedTenantId)

  const send = useCallback(
    ({
      data,
      isGlobalTemplate,
      templateId,
    }: {
      data: SaveOrEditTemplateForm
      isGlobalTemplate: boolean
      templateId: number
    }) => {
      if (tenantId) {
        const message: WsChangeTemplateDocument = {
          change: {
            data,
            id: templateId.toString(),
            model: isGlobalTemplate
              ? WsMessageModel.GlobalTemplateDocument
              : WsMessageModel.LocalTemplateDocument,
          },
          tenantId,
        }
        sendWrapper(message)
      }
    },
    [tenantId, sendWrapper],
  )

  return {
    changeData,
    error,
    loading,
    send,
  }
}

export const useChangeChannelVisibility = (props?: Props) => {
  const { send: sendWrapper, loading, error, data: changeData } = useChange(props)

  const tenantId = useAppSelector(selectSelectedTenantId)

  const send = useCallback(
    (data: WsChangeChannelVisibility[WsOperationType.Change]['data']) => {
      if (tenantId) {
        const message: WsChangeChannelVisibility = {
          change: {
            data,
            model: WsMessageModel.ChannelVisibility,
          },
          tenantId,
        }
        sendWrapper(message)
      }
    },
    [tenantId, sendWrapper],
  )

  return {
    changeData,
    error,
    loading,
    send,
  }
}

export const useChangeTenant = (props?: Props) => {
  const { send: sendWrapper, loading, error, data: changeData } = useChange(props)

  const sendChange = useCallback(
    (data: CreateOrEditTenantForm, tenantId: number) => {
      const message: WsChangeTenant = {
        change: {
          data: { description: data.description, name: data.tenantName },
          id: tenantId,
          model: WsMessageModel.TenantList,
        },
        tenantId: tenantId,
      }

      sendWrapper(message)
    },
    [sendWrapper],
  )

  return {
    changeData,
    error,
    loading,
    sendChange,
  }
}

export const useChangeCategory = (props?: Props) => {
  const { send: sendWrapper, loading, error, data: changeData } = useChange(props)

  const tenantId = useAppSelector(selectSelectedTenantId)

  const sendChange = useCallback(
    (data: CreateOrEditCategoriesForm, categoryId: number) => {
      if (tenantId && categoryId) {
        const message: WsChangeCategory = {
          change: {
            data: { name: data.categoryName },
            id: categoryId.toString(),
            model: WsMessageModel.Category,
          },
          tenantId: tenantId,
        }

        sendWrapper(message)
      }
    },
    [sendWrapper, tenantId],
  )

  return {
    changeData,
    error,
    loading,
    sendChange,
  }
}
