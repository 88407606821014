import React, { useCallback, useState } from 'react'
import {
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  ToggleButton as ToggleButtonComponent,
  Typography,
} from '@mui/material'
import { TypographyKeys, getTypographyProp } from '@dis/styles/src/theme'
import { styles } from './styles'
import { SvgImage } from '../SvgImage/SvgImage'

enum DefaultValues {
  Center = 'center',
  Left = 'left',
  Right = 'right',
}

type DefaultValuesUnion = `${DefaultValues}`

export type SwitchButtonProps<ValuesUnion = DefaultValuesUnion> = {
  centerDisabled?: boolean
  centerIcon?: string
  centerIconSize?: TypographyKeys
  centerText?: string
  centerValue?: string
  className?: string
  fontSize?: TypographyKeys
  forceWidth?: string
  isManagementOfTenant?: boolean
  leftDisabled?: boolean
  leftIcon?: string
  leftIconSize?: TypographyKeys
  leftText?: string
  leftValue?: string
  onChange?: (mode: ValuesUnion) => void
  rightDisabled?: boolean
  rightIcon?: string
  rightIconSize?: TypographyKeys
  rightText?: string
  rightValue?: string
  value?: string
}

export const SwitchButton = <ValuesUnion = DefaultValuesUnion,>({
  className,
  onChange,
  value,
  leftDisabled,
  leftIcon,
  leftIconSize = 'paragraph.large',
  leftText,
  leftValue = DefaultValues.Left,
  rightDisabled,
  rightIcon,
  rightIconSize = 'paragraph.large',
  rightText,
  rightValue = DefaultValues.Right,
  fontSize = 'paragraph.medium',
  forceWidth,
  centerDisabled,
  centerIcon,
  centerIconSize = 'paragraph.large',
  centerText,
  centerValue = DefaultValues.Center,
}: SwitchButtonProps<ValuesUnion>) => {
  const [selectedMode, setSelectedMode] = useState<string | undefined>(value)

  const setMode = useCallback<NonNullable<ToggleButtonGroupProps['onChange']>>(
    (event, mode: string | null) => {
      if (mode !== null) {
        if (!value) {
          setSelectedMode(mode)
        }

        onChange?.(mode as ValuesUnion)
      }
    },
    [value, onChange],
  )

  return (
    <ToggleButtonGroup
      className={`${styles.toggleButtonGroup} ${className}`}
      value={value || selectedMode}
      exclusive
      onChange={setMode}
      size="small">
      <ToggleButtonComponent
        disabled={leftDisabled}
        value={leftValue}
        aria-label="left aligned"
        className={styles.toggleButton}
        style={{ width: forceWidth || 'auto' }}>
        {!!leftIcon && (
          <SvgImage
            src={leftIcon}
            width={getTypographyProp(leftIconSize, 'fontSize')}
            height={getTypographyProp(leftIconSize, 'fontSize')}
            className={leftText ? styles.icon : undefined}
          />
        )}
        {!!leftText && (
          <Typography fontSize={getTypographyProp(fontSize, 'fontSize')}>{leftText}</Typography>
        )}
      </ToggleButtonComponent>
      {centerText && (
        <ToggleButtonComponent
          disabled={centerDisabled}
          value={centerValue}
          aria-label="center aligned"
          className={styles.toggleButton}
          style={{ width: forceWidth || 'auto' }}>
          {!!centerIcon && (
            <SvgImage
              src={centerIcon}
              width={getTypographyProp(centerIconSize, 'fontSize')}
              height={getTypographyProp(centerIconSize, 'fontSize')}
              className={centerText ? styles.icon : undefined}
            />
          )}
          {!!centerText && (
            <Typography className={styles.text} fontSize={getTypographyProp(fontSize, 'fontSize')}>
              {centerText}
            </Typography>
          )}
        </ToggleButtonComponent>
      )}
      <ToggleButtonComponent
        disabled={rightDisabled}
        value={rightValue}
        aria-label="right aligned"
        className={styles.toggleButton}
        style={{ width: forceWidth || 'auto' }}>
        {!!rightIcon && (
          <SvgImage
            src={rightIcon}
            width={getTypographyProp(rightIconSize, 'fontSize')}
            height={getTypographyProp(rightIconSize, 'fontSize')}
            className={rightText ? styles.icon : undefined}
          />
        )}
        {!!rightText && (
          <Typography className={styles.text} fontSize={getTypographyProp(fontSize, 'fontSize')}>
            {rightText}
          </Typography>
        )}
      </ToggleButtonComponent>
    </ToggleButtonGroup>
  )
}
