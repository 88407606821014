import ReactDOM from 'react-dom/client'
import './index.scss'
import '@dis/languages'
import { ErrorBoundary } from '@dis/components'
import {
  AppLoader,
  CentralModalDialog,
  CentralModalLoader,
  MsalProvider,
  ThemeProvider,
} from '@dis/modules'
import { RouterProvider } from '@dis/routing/src/RouterProvider'

import { CentralNotificationPopup } from '@dis/modules/src/CentralNotificationPopup/CentralNotificationPopup'
import { Redux } from './Redux'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <ErrorBoundary>
    <Redux>
      <ThemeProvider>
        <AppLoader>
          <MsalProvider>
            <RouterProvider />
          </MsalProvider>
        </AppLoader>
        <CentralModalLoader />
        <CentralModalDialog />
        <CentralNotificationPopup />
      </ThemeProvider>
    </Redux>
  </ErrorBoundary>,
)
