import React, { useCallback } from 'react'
import { FormGroup, TextareaAutosize, TextareaAutosizeProps } from '@mui/material'
import { useController, Control } from 'react-hook-form'
import { cx } from '@emotion/css'
import { ErrorNotice } from '../ErrorNotice/ErrorNotice'
import { submitByVirtualButton } from '../../utils'
import { styles } from './styles'

export type Props = Omit<TextareaAutosizeProps, 'value'> & {
  className?: string
  color?: string
  formControl: Control<any>
  hoverTextColor?: string
  isControlled?: boolean
  isMultiline?: boolean
  maxLength?: number
  name: string
  rows?: number
  shouldUnregister?: boolean
  submitOnBlur?: boolean
  textAlign?: string
  value?: string | number
  width?: string
}

export const TextArea = ({
  name,
  className,
  formControl,
  shouldUnregister = false,
  submitOnBlur = false,
  ...restProps
}: Props) => {
  const restPropsOnChange = restProps.onChange
  const restPropsOnBlur = restProps.onBlur
  const {
    field: { onBlur, onChange, value },
    fieldState: { error },
  } = useController({
    control: formControl,
    name,
    shouldUnregister,
  })

  const onChangeWrapper = useCallback<NonNullable<TextareaAutosizeProps['onChange']>>(
    (e) => {
      onChange(e)
      restPropsOnChange?.(e)
    },
    [restPropsOnChange, onChange],
  )

  const onBlurWrapper = useCallback<NonNullable<TextareaAutosizeProps['onBlur']>>(
    (e) => {
      onBlur()
      restPropsOnBlur?.(e)

      if (submitOnBlur) {
        const target = e.target as HTMLTextAreaElement | undefined
        submitByVirtualButton(target)
      }
    },
    [onBlur, restPropsOnBlur, submitOnBlur],
  )

  return (
    <FormGroup>
      <>
        <TextareaAutosize
          {...restProps}
          autoComplete="false"
          onChange={onChangeWrapper}
          onBlur={onBlurWrapper}
          className={cx(styles.textarea, className)}
          value={value || ''}
        />
        <ErrorNotice error={error} />
      </>
    </FormGroup>
  )
}
