export const submitByVirtualButton = (target: HTMLElement | undefined) => {
  const virtualBtn: HTMLButtonElement = document.createElement('button')
  virtualBtn.type = 'submit'

  if (target?.appendChild) {
    target.appendChild(virtualBtn)

    virtualBtn.click()

    target.removeChild(virtualBtn)
  }
}

export const formArrayCompare = <T extends Record<string, any>>(
  actualData: Array<T>,
  newData: Array<T>,
  ident: string,
) => {
  const appendData: Array<T> = []
  const removeData: Array<string> = []
  const updateData: Array<T> = []

  const actualDataMap: Record<string, { data: T; hash: string }> = {}
  const newDataMap: Record<string, { data: T; hash: string }> = {}

  actualData.forEach((data) => {
    const hash = JSON.stringify(data)
    const key = data[ident] as string

    actualDataMap[key] = {
      data,
      hash,
    }
  })

  newData.forEach((data) => {
    const hash = JSON.stringify(data)

    newDataMap[data[ident]] = {
      data,
      hash,
    }

    if (!actualDataMap[data[ident]]) {
      appendData.push(data)
    } else if (hash !== actualDataMap[data[ident]].hash) {
      updateData.push(data)
    }
  })

  actualData.forEach((data) => {
    if (!newDataMap[data[ident]]) {
      removeData.push(data[ident])
    }
  })

  return {
    appendData,
    removeData,
    updateData,
  }
}
